<template>
  <v-container class="pa-0 ma-0 px-0 mx-0" fluid>
    <v-card class="py-0">
      <v-card-text class="pa-0">
        <v-row no-gutters>
          <!-- start: filter -->
          <v-col class="pt-0" cols="12">
            <v-form ref="filtersForm" lazy-validation>
              <v-row class="pa-0 ma-0 mx-0">
                <v-col cols="12" sm="6">
                  <BaseDateInput v-model="startDateTime" :disabled="this.dates_loading"
                    :label="$t('accounting.lang_dailyReportStart')" :loading="this.dates_loading" :rules="[v => !!v ]" hide-details
                    dense outlined type="date" />
                </v-col>
                <v-col cols="12" sm="6">
                  <BaseDateInput v-model="endDateTime" :disabled="this.dates_loading"
                    :label="$t('accounting.lang_dailyReportEnd')" :loading="this.dates_loading" :rules="[v => !!v]" hide-details
                    dense outlined type="date" />
                </v-col>

              </v-row>
            </v-form>
          </v-col>
          <!-- end: filter -->

          <!-- start: datatable -->
          <v-col class="pb-0 mb-0" cols="12">
            <Datatable ref="VoucherBeeOrdersOverview" :api-endpoint="ENDPOINTS.DATATABLES.VOUCHERBEE.ORDERS"  :custom-buttons="custombuttons" @resendEmail="reSendInvoiceAndCardsEmail"
              :datatable-headers="datatableHeaders" excel-file-name="voucherbee-orders-overview">
              <template v-slot:item.total="{item}">
                {{ Number(item.total)|currency }}
              </template>
            </Datatable>
          </v-col>
          <!-- end: datatable -->
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
  
  <script>
  import Datatable from "@/components/datatable/Datatable.vue";
  import countTo from 'vue-count-to';
  import {ENDPOINTS} from "@/config";
  import {Events} from "@/plugins/events";
  import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
  import {library} from '@fortawesome/fontawesome-svg-core';
  import {faEnvelope} from '@fortawesome/pro-light-svg-icons';
  import moment from "moment";
  import BaseDateInput from "@/components/common/BaseDateInput.vue";
  
  library.add(faEnvelope);
  
  export default {
    name: "OrdersOverviewComponent",
    components: {Datatable, countTo, FontAwesomeIcon, BaseDateInput},
    data() {
      return {
        ENDPOINTS,
        startDateTime: "",
        endDateTime: "",
        dates_loading: false,
        loadingEmail: false,
        datatableHeaders: [
          {
            text: this.$t('generic.lang_id'),
            align: 'left',
            value: "uuid",
            hide: true
          },
          {text: this.$t('generic.lang_id'), value: "id", hide: true},
          {text: this.$t('generic.lang_date'), value: "date"},
          {text: this.$t('generic.lang_seller_id'), value: "soldBy" , hide: true},
          {text: this.$t('generic.lang_customer'), value: "customer"},
          {text: this.$t('erp.lang_stornoGrund'), value: "reason", hide: true},
          {text: this.$t('erp.lang_totalsolditems'), value: "totalItems"},
          {text: this.$t('erp.lang_ware_total'), value: "total"},
          // {text: this.$t('accounting.lang_paymenthod'), value: "payment"}
        ],
        custombuttons: [
        {
          text: "mdi mdi-email-outline",
          color: "primary",
          eventRow: "resendEmail",
          layout: {
            outlined: false,
            flat: false,
            text: false,
            icon: true
          }
        }],
        statistics: {
          total: 0,
          totalOnline: 0,
          totalCard: 0,
          totalCash: 0,
        },
        loading: false,
      }
    },
    computed: {
      params: function () {
        return {
          startDate: this.startDateTime === "" ? "" : this.$moment.unix(new Date(this.startDateTime).getTime() / 1000).startOf("days").unix(),
          endDate: this.endDateTime === "" ? "" : this.$moment.unix(new Date(this.endDateTime).getTime() / 1000).endOf("days").unix(),
        }
      }
    },
    watch: {
      startDateTime(val) {
        this.$refs.filtersForm.validate();
  
        if (this.$moment(val).isValid() && this.$moment(this.endDateTime).isValid() && this.$moment(val).isBefore(this.$moment(this.endDateTime))) {
          this.$refs.VoucherBeeOrdersOverview.getDataFromApi(this.params);
        } else if (this.$moment(val).isValid() && this.$moment(this.endDateTime).isValid() && this.$moment(val).isAfter(this.$moment(this.endDateTime)))
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t("generic.lang_select_date_before") + ": " + this.endDateTime,
          });
      },
      endDateTime(val) {
        this.$refs.filtersForm.validate();
  
        if (this.$moment(val).isValid() && this.$moment(this.startDateTime).isValid() && this.$moment(val).isAfter(this.$moment(this.startDateTime))) {
          this.$refs.VoucherBeeOrdersOverview.getDataFromApi(this.params);
        } else if (this.$moment(val).isValid() && this.$moment(this.startDateTime).isValid() && this.$moment(val).isBefore(this.$moment(this.startDateTime)))
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_select_date_after') + ": " + this.startDateTime
          });
      },
    },
    methods: {
      getDate(timeStamp) {
        return moment.unix(timeStamp).format('DD.MM.YY HH:mm')
      },
      reSendInvoiceAndCardsEmail(item) {
        this.loadingEmail = true;
        this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CASHIER.SUBDOMAIN.GET).then((res) => {
          if (res.data.STATUS === 'SUCCESS') {
            if (res.data.hasDomains) {
              let domain = res.data.domains.find(d => d.service === 'giftcard')
              if (domain) {
                let giftcardSubDomain = domain.domain;

                this.$swal({
                  title: this.$t('voucherbee.lang_resendInvoiceAndGiftCards'),
                  text: this.$t('voucherbee.lang_resendInvoiceAndGiftCardsMSG'),
                  icon: "warning",
                  confirmButtonText: this.$t('generic.lang_yes'),
                  cancelButtonText: this.$t('generic.lang_cancel'),
                  showCancelButton: true,
                  showLoaderOnConfirm: true,
                  preConfirm: () => {
                    this.loadingEmail = true;
                    let serviceApiAxios = this.axios.create({
                      baseURL: process.env.VUE_APP_SERVICE_API_HOST
                    });

                    delete serviceApiAxios.defaults.headers.common['3POSAPITOKEN'];
                    delete serviceApiAxios.defaults.headers.common['3POSBS'];
                    delete serviceApiAxios.defaults.headers.common['3POSBSID'];
                    serviceApiAxios.post(`/voucherbee/vouchers/resend/${item.uuid}?service_domain=${giftcardSubDomain.split('.')[0]}`,
                    ).then((res) => {
                      if (res.status === 200) {
                        Events.$emit("showSnackbar", {
                          message: this.$t("generic.lang_ActionSuccessful"),
                          color: "success"
                        });

                      } else {
                        Events.$emit("showSnackbar", {
                          message: res.data.msg || this.$t('generic.lang_errorOccurred'),
                          color: "error"
                        });
                      }
                    }).catch(err => {
                      Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                      });
                    }).finally(() => {
                      this.loadingEmail = false;
                    });
                  },
                  allowOutsideClick: () => !this.$swal.isLoading,
                });
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_noDomainHasBeenFound'),
                  color: "error"
                });
              }
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_noDomainHasBeenFound'),
                color: "error"
              });
            }
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
              color: "error"
            });
          }
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          });
        }).finally(() => {
          this.loadingEmail = false;
        })
      }
    },
    mounted() {
      
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  